import styled from "styled-components";
import { layout, space, flexbox } from "styled-system";

const Flex = styled.div(
  {
    display: "flex"
  },
  flexbox,
  space,
  layout
);

export default Flex;
