import React, { useEffect, useState } from "react";
import moment from 'moment'
import Box from "../components/styled/Box";
import Flex from "../components/styled/Flex";
import Header from "../components/Header";
import Api from "../api";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn
} from "office-ui-fabric-react/lib/DetailsList";

const columns = [
  {
    key: "username",
    name: "username",
    maxWidth: 50,
    fieldName: "username"
  },
  {
    key: "email",
    name: "email",
    fieldName: "email",
  },
  {
    key: "created",
    name: "created",
    fieldName: "created",
    onRender: o => <b>{moment(o.created).fromNow()}</b>
  },
  {
    key: "lastLogin",
    name: "lastLogin",
    fieldName: "lastLogin",
    onRender: o => <b>{moment(o.lastLogin).fromNow()}</b>
  },
  {
    key: "loginIP",
    name: "loginIP",
    fieldName: "loginIP",
  }
  
];
function App() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    new Api().get_portal_all().then(res => {
      console.log(res);
      setUsers(res);
    });
  }, []);

  return (
    <Box width={[1, 2 / 3, 2 / 5]} m="auto" height="100vh">
      <Flex height="100vh" flexDirection="column">
        <Header />
        {users.length !== 0 && (
          <DetailsList
            items={users}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            columns={columns}
            selectionPreservedOnEmptyClick={true}
            compact={false}
            selectionMode={SelectionMode.none}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        )}
      </Flex>
    </Box>
  );
}

export default App;
