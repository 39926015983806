import querystring from "query-string";
import moment from "moment";

export default class Fapi {
  constructor({ baseUri, token }) {
    this.baseUri = baseUri || "";
    this.headers = {
      "Content-Type": "application/json",
      ...(token ? { Authorization: "Bearer " + token } : {})
    };
  }

  callApi({ method, path, data }) {
    return fetch(this.baseUri + path, {
      method: method,
      mode: "cors",
      headers: this.headers,
      body: data !== null ? JSON.stringify(data) : null
    }).then(response => {
      {
        if (response.ok) {
          return response.json();
        } else {
          throw response
        }
      }
    });
  }

  getData({ path, data = {} }) {
    const paramsString = querystring.stringify({
      ...data,
      timeZone: moment().format("Z")
    });

    const fullpath = path + (paramsString === "" ? "" : "?" + paramsString);

    return this.callApi({ method: "GET", path: fullpath, data: null });
  }

  deleteData({ path, data }) {
    return this.callApi({
      method: "DELETE",
      path: path,
      data
    });
  }

  postData({ path, data, isRefreshTokenApi }) {
    return this.callApi({
      method: "POST",
      path,
      data,
      isRefreshTokenApi
    });
  }

  putData({ path, data }) {
    return this.callApi({
      method: "PUT",
      path,
      data
    });
  }
}
