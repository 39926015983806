import React from "react";
import styled from "styled-components";
import Box from "./styled/Box";
import Text from "./styled/Text";
import Flex from "./styled/Flex";

const Fixed = styled.div({
  position: "fixed",
  top: 0,
  left: 0
});
const AppLogo = styled.img({
  display: "block",
  width: 50,
  height: 50
});
export default function() {
  return (
    <Fixed>
      <Box p={3}>
        <Flex>
          <AppLogo src="/dashboard2x64.png" />
          <Box pl={3}>
            <Text fontSize={3} fontWeight={800}>
              Portal
            </Text>
            <br />
            <Text>see what's going on</Text>
          </Box>
        </Flex>
      </Box>
    </Fixed>
  );
}
