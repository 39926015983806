import Fapi from "./Fapi";
import consts from "../consts";

export default class Api {
  constructor() {
    // this.token = new Auth().getToken();
    this.token =
      process.env.NODE_ENV === "development"
        ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWJiMWFiNDRhMmIyZjE0NTFjZGUzZDM3IiwidXNlcm5hbWUiOiJmb3pnIiwiZW1haWwiOiIxMjMxMjNAZ21haWwuY29tIiwicmVzZXRQYXNzd29yZFRva2VuIjpudWxsLCJ2YWxpZGF0ZWQiOmZhbHNlLCJpc0xvY2tlZCI6ZmFsc2UsImxvY2tlZFRpbWVFeHBpcmUiOm51bGwsInBhc3N3b3JkQXR0ZW1wdENvdW50IjowLCJsYXN0TG9naW4iOm51bGwsImNyZWF0ZWQiOiIyMDE4LTEwLTAxVDA1OjA2OjExLjgzOVoiLCJ1cGRhdGVkIjoiMjAxOC0xMC0wMVQwNTowNjoxMS44MzlaIiwibG9naW5JUCI6Ijo6MSJ9LCJpYXQiOjE1NjE5MTA1NTJ9.e6mw5AQVA_nDy9hg09kL5UXunnznGw91NdnPwdGECt4"
        : localStorage.getItem("TodomorrowTOKEN");
    this.baseUri =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3020/portal"
        : "https://api.fozg.net/portal";
  }

  getAllTodos() {
    return new Fapi({ baseUri: this.baseUri, token: this.token }).getData({
      path: ""
    });
  }

  sync({ payload, type }) {
    return new Fapi({ baseUri: this.baseUri, token: this.token }).postData({
      path: "",
      data: {
        payload,
        type
      }
    });
  }
  get_current_user() {
    return new Fapi({ baseUri: "", token: this.token }).getData({
      path: consts.API_CURRENT_USER
    });
  }

  get_portal_all() {
    return new Fapi({ baseUri: this.baseUri, token: this.token }).getData({
      path: "/all"
    });
  }
}
