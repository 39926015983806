import consts from "../consts";
import Api from "../api";

export default class Auth {
  static isAuthen = () => {
    let userinfo = localStorage.getItem(consts.USER_INFO_STORAGE_KEY);
    if (userinfo === null) {
      return false;
    } else {
      return JSON.parse(userinfo);
    }
  };

  static authenUrlCallback = async doCheck => {
    var url = new URL(window.location.href);
    var auth = url.searchParams.get("token") || url.searchParams.get("auth");
    if (auth !== null) {
      doCheck();
      localStorage.setItem("TodomorrowTOKEN", auth);
      try {
        var user = await new Api().get_current_user();
        var storeValue = {
          token: auth,
          ...user
        };
        if (user) {
          localStorage.setItem(
            consts.USER_INFO_STORAGE_KEY,
            JSON.stringify(storeValue)
          );
        }
        window.history.pushState(null, "", "/");
      } catch (res) {
        if (res.status === 403 || res.status === 401) {
          localStorage.removeItem(consts.USER_INFO_STORAGE_KEY);
          localStorage.removeItem("TodomorrowTOKEN");
          window.history.pushState(null, "", "/");
        }
        window.history.pushState(null, "", "/");
      }
    }
  };
}
