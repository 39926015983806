import React, { useState, useEffect } from "react";

import Home from "./containers/Home";
import Welcome from "./containers/Welcome";

import Auth from "./auth";
export default function() {
  const [isAuthenting, setIsAuthenting] = useState(false);

  useEffect(() => {
    checkAuth();
  }, isAuthenting);

  const checkAuth = async () => {
    await Auth.authenUrlCallback(() => {
      setIsAuthenting(true);
    });
    setIsAuthenting(false);
  };
  if (!Auth.isAuthen()) {
    return <Welcome isAuthenting={isAuthenting} />;
  }
  return <Home />;
}
