import React from "react";
import styled from "styled-components";
import { space, width, height, right, top } from "styled-system";
import Box from "../components/styled/Box";
import Text from "../components/styled/Text";
import Flex from "../components/styled/Flex";
import consts from "../consts";
import { NONAME } from "dns";

const Wrap = styled.div({
  background: "url(/images/bg.svg)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "100%",
  width: "100vw",
  minHeight: "100vh",
  backgroundColor: "#FFEFD6",
  overflowX: "hidden"
});
const Logo = styled.img({
  display: "block",
  width: "6em",
  height: "6em"
});
const LoginButton = styled.button(
  {
    border: "none",
    backgroundColor: "#F77877",
    color: "#fff",
    fontWeight: "700",
    borderRadius: 20,
    textAlign: "center",
    display: "inline-block",
    cursor: "pointer",
    outline: "none",
    ":hover": {
      backgroundColor: "#F77688"
    },
    span: {
      background: "#fff",
      color: "#000",
      padding: "0 5px",
      borderRadius: 4,
      fontSize: 14
    }
  },
  space
);
const Dots = styled.div(
  {
    backgroundImage: `url(/images/dots.png)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute"
  },
  right,
  width,
  height,
  top
);
const AppPreview = styled.div(
  {
    position: "absolute",
    top: "30%",
    borderRadius: 20,
    boxShadow: "0 10px 20px rgba(0,0,0,.02)",
    img: {
      margin: 0,
      padding: 0,
      display: "block"
    }
  },
  right
);
const Snap = styled.img({}, width, height);

function onLoginClick() {
  window.location = consts.AUTHEN_URL;
}

export default function({ isAuthenting }) {
  return (
    <Wrap>
      <Box width={[1, 2 / 3, "600px", "800px"]} m="auto" p={[30, 0, 0, 0]}>
        <Box pt={[3, "5em"]}>
          <Logo src={`/images/Todomorrow.png`} />
        </Box>
        <Flex flexDirection={["column", "column", "row", "row"]}>
          <Box>
            <Box pt={"4em"}>
              <Text fontSize={4} fontWeight="700">
                Todomorrow
              </Text>
              <br />
              <Text fontSize={2}>Easy way to plan your next day todo.</Text>
            </Box>
            <Box mt={[4]}>
              <LoginButton
                py={3}
                px={5}
                onClick={onLoginClick}
                disabled={isAuthenting}
              >
                {isAuthenting ? (
                  "Logging you in.."
                ) : (
                  <>
                    Login with fozg<span>id</span>
                  </>
                )}
              </LoginButton>
            </Box>
          </Box>
          <Flex
            style={{ position: "relative" }}
            flex={1}
            mt={[50, 50, 50, 0]}
            flexDirection="column"
          >
            <Dots
              width={[200, 200, 210, 200]}
              height={[200, 200, 210, 200]}
              top={[-50, -100, -40, -50]}
              right={[-20, -100, -150, -100]}
            />
            <AppPreview right={["unset", 0, -150, -50]}>
              <Snap
                src="/images/app_preview.png"
                width={["100%", "100%", "90%", "100%"]}
              />
            </AppPreview>
          </Flex>
        </Flex>
      </Box>
    </Wrap>
  );
}
