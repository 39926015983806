export default {
  space: [0, 2, 4, 8, 16, 32],
  fontSizes: [14, 16, 18, 24, 32],
  fonts: {
    default: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
    sans: "system-ui, sans-serif",
    mono: "Menlo, monospace"
  },
  colors: {
    blue: "#07c",
    tomato: "tomato",
    purple: "purple",
    white: "#fff"
  },
  buttons: {
    primary: {
      color: "white",
      backgroundColor: "blue",
      cursor: "pointer",
      borderRadius: 5,
      "&:hover": {
        backgroundColor: "black"
      }
    }
  }
};
